<template>
  <div>
    <div class="row">
      <!-- <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-search-box ref="searchbox" @enter="getList">
          <template slot="search">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-dept type="search" name="deptCd" :isFirstValue="false" v-model="searchParam.deptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-vendor type="search" name="vendorCd" :isFirstValue="false" v-model="searchParam.vendorCd" />
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="userTable"
          title="사용자 목록"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          :columnSetting="false"
          :isFullScreen="false"
          selection="multiple"
          rowKey="userId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-select
          dense
          filled
          bg-color="blue-grey-1"
          v-model="pick"
          :options="options"
          label=""
          color="teal"
          options-selected-class="text-deep-orange"
          class="user-pop-search-type"
        >
          <template v-slot:selected>
            
            <q-list dense>
              <q-item tag="label">
                <q-item-section avatar>
                  <q-icon :name="pick.icon" class="text-deep-orange"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-primary"><b>{{pick.label}}</b></q-item-label>
                  <q-item-label caption>
                    {{pick.description}}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </template>
          <template v-slot:option="scope">
            <q-item
              v-bind="scope.itemProps"
              v-on="scope.itemEvents"
            >
              <q-item-section avatar>
                <q-icon :name="scope.opt.icon"></q-icon>
              </q-item-section>
              <q-item-section>
                <q-item-label v-html="scope.opt.label"></q-item-label>
                <q-item-label caption>{{ scope.opt.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <c-search-box ref="searchbox" @enter="getList">
          <template slot="search">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-dept v-show="pick.value==='userInAll'" type="search" :isFirstValue="false" name="deptCd" v-model="searchParam.deptCd" />
              <c-vendor
                v-show="pick.value==='userOutAll'"
                :plantCd="popupParam.plantCd"
                companyFlag="N"
                label="업체"
                name="vendorCd"
                v-model="searchParam.vendorCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-select
                v-show="pick.value==='userOutAll'"
                type="search"
                codeGroupCd="SAFETY_VENDOR_ORG_ROLE"
                itemText="codeName"
                itemValue="code"
                name="projectRoleCd"
                label="역할"
                v-model="searchParam.projectRoleCd"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="userTable"
          title="사용자 목록"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          :columnSetting="false"
          :isFullScreen="false"
          selection="multiple"
          rowKey="userId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="margin: auto 0; line-height: 35px; text-align:center;">
        <c-btn label="확인" icon="arrow_forward_ios" color="blue-grey-4" @btnClicked="addApprLine('AKC0000004')" />
        <c-btn label="합의" icon="arrow_forward_ios" color="green-7" @btnClicked="addApprLine('AKC0000002')" />
        <c-btn label="결재" icon="arrow_forward_ios" color="blue-7" @btnClicked="addApprLine('AKC0000003')" />
        <c-btn label="회람" icon="arrow_forward_ios" color="blue-grey-4" @btnClicked="addApprLine('AKC0000009')" /><br><br>
        <c-btn label="삭제" icon="arrow_back_ios" color="red" @btnClicked="delApprLine" />
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-card title="결재선" class="cardClassDetailForm cardClassDetailFormLine" height="270px">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="결재선 변경" icon="save" @btnClicked="select" />
            </q-btn-group>
          </template>
          <template slot="card-detail" style="padding: 5px !important">
            <div class="col-12">
              <draggable
                tag="ul"
                :list="linegrid.data"
                class="list-group"
                handle=".linehandle"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                @change="moveApprLine"
                :move="checkMove"
              >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <li
                    class="list-group-item"
                    v-for="(element, idx) in linegrid.data"
                    :key="element.approvalUserId + element.approvalKindCd"
                    :class="{'linehandle': element.draggable}"
                  >
                    <i class="material-icons linehandley" v-if="element.draggable">reorder</i>
                    <i class="material-icons linehandlex no-move" v-if="!element.draggable">reorder</i>
                    <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                    <div class="linetext linespan2">{{ element.deptName }} </div>
                    <div class="linetext linespan3">{{ element.approvalUserName }} </div>
                    <i class="material-icons lineclose" v-if="element.draggable" @click="removeAt(idx)">clear<q-tooltip>결재선 삭제</q-tooltip></i>
                  </li>
                </transition-group>
              </draggable>
            </div>
          </template>
        </c-card>
        <br>
        <c-table
          ref="lineTable2"
          title="회람"
          :columnSetting="false"
          :isFullScreen="false"
          :hideBottom="true"
          :usePaging="false"
          :filtering="false"
          :columns="linegrid2.columns"
          :data="linegrid2.data"
          :gridHeight="linegrid2.height"
          selection="multiple"
          rowKey="approvalUserId"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import draggable from 'vuedraggable';
export default {
  components: {
      draggable,
  },
  name: 'appr-line-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          apprlinedata: [],
          apprlinedata2: [],
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      dragging: false,
      drag: false,
      grid: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '500px'
      },
      linegrid: {
        data: [],
      },
      linegrid2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '272px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        vendorCd: '',
        userName: '',
        projectRole: null,
        projectRoleCd: null,
        innerFlag: 'Y'
      },
      pick: {
        value: 'projectOrg'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
    pick() {
      this.grid.data = [];
      if (this.pick.value === 'projectOrg') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.pjm.org.list.url;
        this.grid.columns = [
          {
            name: 'projectRoleName',
            field: 'projectRoleName',
            label: '역할',
            align: 'center',
            style: "width:200px",
            sortable: false,
          },
          {
            name: 'projectRoleUserName',
            field: 'projectRoleUserName',
            label: '담당자',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'roleStartDt',
            field: 'roleStartDt',
            label: '근무시작일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'roleEndDt',
            field: 'roleEndDt',
            label: '근무종료일자',
            align: 'center',
            sortable: false,
          },
          {
            name: 'roleAppointDt',
            field: 'roleAppointDt',
            label: '선임일자',
            align: 'center',
            sortable: false,
          },
        ]
      } else if (this.pick.value === 'projectVendor' || this.pick.value === 'userOutAll') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        if (this.pick.value === 'projectVendor') {
          this.listUrl = selectConfig.pjm.vendorUser.list.url;
        } else {
          this.listUrl = selectConfig.mdm.user.vendor.list.url;
        }
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '업체',
            align: 'center',
            sortable: true
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'projectRoleName',
            field: 'projectRoleName',
            label: '역할',
            align: 'center',
            style: "width:200px",
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            style: 'width:130px',
            label: '생년월일',
            align: 'center',
            sortable: true
          },
        ]
      } else if (this.pick.value === 'selfVendor') {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.pjm.vendorUser.self.url;
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ]
      } else {
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
        this.listUrl = selectConfig.mdm.user.list.url
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ]
      }

      this.getList();
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    options() {
      let val = [
        {
          label: '시공사측 사용자 전체 조회',
          value: 'userInAll',
          description: '사용자 관리 목록을 조회합니다.',
          icon: 'supervised_user_circle'
        },
        {
          label: '도급업체별 사용자 전체 조회',
          value: 'userOutAll',
          description: '도급업체별 사용자 목록을 조회합니다.',
          icon: 'supervised_user_circle'
        },
      ];
      return val;
    }
  },
  methods: {
    moveApprLine() {
      let _cnt = 1;
      this.$_.forEach(this.linegrid.data, item => {
        item.approvalLineNo = _cnt;
        _cnt++;
      })
    },
    checkMove: function(e) {
      if (e.draggedContext.futureIndex === 0) {
        return false;
      }
    },
    init() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.user.list.url
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.pick = this.options[0];
  
      this.getApprLine();
    },
    getList() {
      let _searchParam = {
        userName: this.searchParam.userName,
      }
      if (this.pick.value === 'userOutAll') {
        this.$set(_searchParam, 'deptCd', this.searchParam.vendorCd)
        this.$set(_searchParam, 'innerFlag', 'N')
        this.$set(_searchParam, 'projectRoleCd', this.searchParam.projectRoleCd)
      } else {
        this.$set(_searchParam, 'deptCd', this.searchParam.deptCd)
        this.$set(_searchParam, 'innerFlag', 'Y') 
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = _searchParam;
      this.$http.request((_result) => {
        /**
         * 받는 쪽에서는 동일한 props로 받기 위함
         */
        if (this.pick.value === 'projectOrg') {
          this.$_.forEach(_result.data, item => {
            this.$set(item, 'userId', item.projectRoleUserId)
            this.$set(item, 'userName', item.projectRoleUserName)
          })
        }
        this.grid.data = _result.data;
      },);
    },
    getApprLine() {
      this.$_.extend(this.linegrid.data, this.popupParam.apprlinedata);
      this.$_.extend(this.linegrid2.data, this.popupParam.apprlinedata2);
    },
    select() {
      let returnLineData = {
        apprlinedata: this.linegrid.data,
        apprlinedata2: this.linegrid2.data,
      };
      this.$emit('closePopup', returnLineData);
    },
    addApprLine(_kindCd) {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (_kindCd == 'AKC0000009') { // 회람자 추가
          this.$_.forEach(selectData, item => {
            if (this.linegrid2.data.map(function(e) {return e.approvalUserId;}).indexOf(item.userId) < 0) {
              this.linegrid2.data.push({
                approvalKindCd: _kindCd,
                approvalKindName: this.getApprKindName(_kindCd),
                approvalUserId: item.userId,
                approvalUserName: item.userName,
                deptName: item.deptName,
                approvalLineNo: this.linegrid2.data.length + 1001,
                sysApprovalRequestId: '',
                approvalDt: null,
                approvalStatusCd: '',
              })
            }
          });
        } else {
          this.$_.forEach(selectData, item => {
            // 목록에 중복된 사용자 제외 (결재유형+사용자가 동일하지 않은 사용자만 추가 가능)
            if (this.linegrid.data.map(function(e) {return e.approvalUserId + e.approvalKindCd;}).indexOf(item.userId + _kindCd) < 0) {
              this.linegrid.data.push({
                approvalKindCd: _kindCd,
                approvalKindName: this.getApprKindName(_kindCd),
                approvalUserId: item.userId,
                approvalUserName: item.userName,
                deptName: item.deptName,
                approvalLineNo: this.linegrid.data.length + 1,
                draggable: true,
                sysApprovalRequestId: '',
                approvalDt: null,
                approvalStatusCd: '',
              })
            }
          });
        }
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
      }
    },
    removeAt(idx) {
      this.linegrid.data.splice(idx, 1);
      let _cnt = 1;
      this.$_.forEach(this.linegrid.data, item => {
        item.approvalLineNo = _cnt;
        _cnt++;
      })
    },
    delApprLine() {
      let selectData2 = this.$refs['lineTable2'].selected.filter( x => {
        return x.approvalKindCd != 'AKC0000001'
      });
      if (selectData2 && selectData2.length > 0) {
        this.$_.forEach(selectData2, item => {
          this.linegrid2.data = this.$_.reject(this.linegrid2.data, { approvalUserId: item.approvalUserId })
        })
      }
    },
    getApprKindName(_cd) {
      let name = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000010':
          name = '재상신';
          break;
        case 'AKC0000001':
          name = '상신';
          break;
        case 'AKC0000002':
          name = '합의';
          break;
        case 'AKC0000003':
          name = '결재';
          break;
        case 'AKC0000004':
          name = '확인';
          break;
        case 'AKC0000009':
          name = '회람';
          break;
      }
      return name;
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000010':
        case 'AKC0000001':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    }
  },
};
</script>

<style scoped>
.linehandley {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  cursor: move;
}
.linehandlex {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  opacity: 0.5;
}
.lineclose {
  float: right;
  padding-top: 4px;
  font-size: 12px;
  margin-right: -10px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.linespan1 {
  min-width: 50px !important;
  text-align: left;
  font-weight: 600;
}
.linespan2 {
  min-width: 120px !important;
  text-align: left;
}
.linetext {
  display: inline-block;
}
.list-group-item {
  padding: 0.4rem  1.25rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.8rem;
}
</style>