var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c("q-select", {
            staticClass: "user-pop-search-type",
            attrs: {
              dense: "",
              filled: "",
              "bg-color": "blue-grey-1",
              options: _vm.options,
              label: "",
              color: "teal",
              "options-selected-class": "text-deep-orange",
            },
            scopedSlots: _vm._u([
              {
                key: "selected",
                fn: function () {
                  return [
                    _c(
                      "q-list",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "q-item",
                          { attrs: { tag: "label" } },
                          [
                            _c(
                              "q-item-section",
                              { attrs: { avatar: "" } },
                              [
                                _c("q-icon", {
                                  staticClass: "text-deep-orange",
                                  attrs: { name: _vm.pick.icon },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "q-item-section",
                              [
                                _c(
                                  "q-item-label",
                                  { staticClass: "text-primary" },
                                  [_c("b", [_vm._v(_vm._s(_vm.pick.label))])]
                                ),
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.pick.description) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "option",
                fn: function (scope) {
                  return [
                    _c(
                      "q-item",
                      _vm._g(
                        _vm._b({}, "q-item", scope.itemProps, false),
                        scope.itemEvents
                      ),
                      [
                        _c(
                          "q-item-section",
                          { attrs: { avatar: "" } },
                          [_c("q-icon", { attrs: { name: scope.opt.icon } })],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", {
                              domProps: { innerHTML: _vm._s(scope.opt.label) },
                            }),
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(_vm._s(scope.opt.description)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.pick,
              callback: function ($$v) {
                _vm.pick = $$v
              },
              expression: "pick",
            },
          }),
          _c(
            "c-search-box",
            { ref: "searchbox", on: { enter: _vm.getList } },
            [
              _c("template", { slot: "search" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-dept", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pick.value === "userInAll",
                          expression: "pick.value==='userInAll'",
                        },
                      ],
                      attrs: {
                        type: "search",
                        isFirstValue: false,
                        name: "deptCd",
                      },
                      model: {
                        value: _vm.searchParam.deptCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "deptCd", $$v)
                        },
                        expression: "searchParam.deptCd",
                      },
                    }),
                    _c("c-vendor", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pick.value === "userOutAll",
                          expression: "pick.value==='userOutAll'",
                        },
                      ],
                      attrs: {
                        plantCd: _vm.popupParam.plantCd,
                        companyFlag: "N",
                        label: "업체",
                        name: "vendorCd",
                      },
                      model: {
                        value: _vm.searchParam.vendorCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "vendorCd", $$v)
                        },
                        expression: "searchParam.vendorCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-select", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.pick.value === "userOutAll",
                          expression: "pick.value==='userOutAll'",
                        },
                      ],
                      attrs: {
                        type: "search",
                        codeGroupCd: "SAFETY_VENDOR_ORG_ROLE",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "projectRoleCd",
                        label: "역할",
                      },
                      model: {
                        value: _vm.searchParam.projectRoleCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParam, "projectRoleCd", $$v)
                        },
                        expression: "searchParam.projectRoleCd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "userTable",
              attrs: {
                title: "사용자 목록",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                gridHeight: _vm.grid.height,
                columnSetting: false,
                isFullScreen: false,
                selection: "multiple",
                rowKey: "userId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1",
          staticStyle: {
            margin: "auto 0",
            "line-height": "35px",
            "text-align": "center",
          },
        },
        [
          _c("c-btn", {
            attrs: {
              label: "확인",
              icon: "arrow_forward_ios",
              color: "blue-grey-4",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000004")
              },
            },
          }),
          _c("c-btn", {
            attrs: {
              label: "합의",
              icon: "arrow_forward_ios",
              color: "green-7",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000002")
              },
            },
          }),
          _c("c-btn", {
            attrs: {
              label: "결재",
              icon: "arrow_forward_ios",
              color: "blue-7",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000003")
              },
            },
          }),
          _c("c-btn", {
            attrs: {
              label: "회람",
              icon: "arrow_forward_ios",
              color: "blue-grey-4",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000009")
              },
            },
          }),
          _c("br"),
          _c("br"),
          _c("c-btn", {
            attrs: { label: "삭제", icon: "arrow_back_ios", color: "red" },
            on: { btnClicked: _vm.delApprLine },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm cardClassDetailFormLine",
              attrs: { title: "결재선", height: "270px" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "결재선 변경", icon: "save" },
                        on: { btnClicked: _vm.select },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "template",
                {
                  staticStyle: { padding: "5px !important" },
                  slot: "card-detail",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "list-group",
                            attrs: {
                              tag: "ul",
                              list: _vm.linegrid.data,
                              handle: ".linehandle",
                              move: _vm.checkMove,
                            },
                            on: {
                              start: function ($event) {
                                _vm.drag = true
                              },
                              end: function ($event) {
                                _vm.drag = false
                              },
                              change: _vm.moveApprLine,
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        [
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                type: "transition",
                                name: !_vm.drag ? "flip-list" : null,
                              },
                            },
                            _vm._l(_vm.linegrid.data, function (element, idx) {
                              return _c(
                                "li",
                                {
                                  key:
                                    element.approvalUserId +
                                    element.approvalKindCd,
                                  staticClass: "list-group-item",
                                  class: { linehandle: element.draggable },
                                },
                                [
                                  element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons linehandley",
                                        },
                                        [_vm._v("reorder")]
                                      )
                                    : _vm._e(),
                                  !element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons linehandlex no-move",
                                        },
                                        [_vm._v("reorder")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "linetext linespan1",
                                      class: _vm.getLineKindColor(
                                        element.approvalKindCd
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(element.approvalKindName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan2" },
                                    [_vm._v(_vm._s(element.deptName) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan3" },
                                    [
                                      _vm._v(
                                        _vm._s(element.approvalUserName) + " "
                                      ),
                                    ]
                                  ),
                                  element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons lineclose",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(idx)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("clear"),
                                          _c("q-tooltip", [
                                            _vm._v("결재선 삭제"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          ),
          _c("br"),
          _c("c-table", {
            ref: "lineTable2",
            attrs: {
              title: "회람",
              columnSetting: false,
              isFullScreen: false,
              hideBottom: true,
              usePaging: false,
              filtering: false,
              columns: _vm.linegrid2.columns,
              data: _vm.linegrid2.data,
              gridHeight: _vm.linegrid2.height,
              selection: "multiple",
              rowKey: "approvalUserId",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }